import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keepNumber } from '@assets/js/regExUtil';
import { getCptt, getCustomerInfo, getDept, getStff } from '@api/public';

async function fetchData() {
  let { unprocessedData, list } = await getCustomerInfo({ status: 2, type: 'abbr', unprocessedData: true });
  let deptList = await getDept();
  let stffList = await getStff();
  let cpttList = await getCptt();

  let indices = {};

  contractMainFormProperties.forEach(({ prop }, index) => {
    if (['cptt_id', 'cust_id', 'scon_dept_id', 'buyer_dept_id', 'scon_stff_id', 'buyer_stff_id'].includes(prop)) indices[prop] = index;
  });

  return {
    [`contractMainFormProperties[${indices['cptt_id']}].options`]: cpttList,
    [`contractMainFormProperties[${indices['cust_id']}].options`]: list,
    [`contractMainFormProperties[${indices['cust_id']}].change`]: val => {
      let { cust_dept_id, cust_dept_name, cust_stff_id, cust_stff_name } = custList.find(({ cust_id }) => cust_id === val);
      contractMainFormProperties[indices['scon_dept_id']].model = cust_dept_id;
      contractMainFormProperties[indices['scon_stff_id']].model = cust_stff_id;
      otherForm.cust_dept_name = cust_dept_name;
      otherForm.cust_stff_name = cust_stff_name;
    },
    custList: unprocessedData,
    [`contractMainFormProperties[${indices['scon_dept_id']}].options`]: deptList,
    [`contractMainFormProperties[${indices['buyer_dept_id']}].options`]: deptList,
    [`contractMainFormProperties[${indices['scon_stff_id']}].options`]: stffList,
    [`contractMainFormProperties[${indices['buyer_stff_id']}].options`]: stffList
  };
}

let custList = [];

export let otherForm = {};

export const tableProperties = [
  store.state.index,
  { label: '托收合同号', prop: 'contract_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '托收供应商', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '公司抬头', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售经办人', prop: 'scon_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '采购经办人', prop: 'buyer_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '货款金额', prop: 'podr_prtotal', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '费用金额', prop: 'podr_fetotal', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '合同总额', prop: 'podr_total', itemType: 'input', input: false, sortable: true, widthAuto: true },
  {
    label: '预计付款日期',
    prop: 'payment_date',
    itemType: 'date',
    input: false,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    widthAuto: true,
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];

export const contractMainFormProperties = [
  {
    label: '托收供应商',
    prop: 'supp_abbr',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '公司抬头',
    prop: 'cptt_id',
    model: '',
    itemType: 'select',
    options: [],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '客户简称',
    prop: 'cust_id',
    model: '',
    itemType: 'select',
    options: [],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true,
    filterable: true
  },
  {
    label: '合同日期',
    prop: 'contract_time',
    model: '',
    formatTime: 'yyyy-MM-dd',
    itemType: 'date',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '销售所属部门',
    prop: 'scon_dept_id',
    model: '',
    itemType: 'select',
    options: [],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '销售经办人',
    prop: 'scon_stff_id',
    model: '',
    itemType: 'select',
    options: [],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '工厂账期',
    prop: 'supp_payment',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    maxlength: 2,
    input: (list, index, val) => (list[index].model = keepNumber(val)),
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '采购所属部门',
    prop: 'buyer_dept_id',
    model: '',
    itemType: 'select',
    options: [],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '采购经办人',
    prop: 'buyer_stff_id',
    model: '',
    itemType: 'select',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '要求开票日期',
    prop: 'request_date',
    model: '',
    itemType: 'date',
    size: 'small',
    formatTime: 'yyyy-MM-dd',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '结算方式',
    prop: 'means_payment',
    model: '',
    itemType: 'select',
    options: [
      { value: '我司结算', lablel: '我司结算' },
      { value: '工厂结算', lablel: '工厂结算' }
    ],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '预计付款日期',
    prop: 'payment_date',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '货款金额',
    prop: 'podr_prtotal',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '费用金额',
    prop: 'podr_fetotal',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '合同总额',
    prop: 'podr_total',
    model: '',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  }
];

export const contractSubTableProperties = [
  { label: '中文名称', prop: 'contract_name', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '部件单位', prop: 'modr_unit', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '数量', prop: 'contract_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '单价(￥)', prop: 'contract_price', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '开票品名', prop: 'prod_hsname', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '金额(￥)', prop: 'contract_amount', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '备注',
    prop: 'contract_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 200,
    subItem: { required: false, disabled: false, maxLength: '255', type: 'popoverInput' }
  }
];

export const feesSubTableProperties = [
  {
    label: '费用名',
    prop: 'contract_fess_name',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: true, disabled: false, maxLength: '20', type: 'input' }
  },
  {
    label: '金额(元)',
    prop: 'contract_fess_amount',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: true, disabled: false, maxLength: '20', type: 'input' }
  },
  {
    label: '备注',
    prop: 'contract_fess_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, disabled: false, maxLength: '255', type: 'popoverInput' }
  }
];

fetchData().then(data => {
  for (const key in data) {
    eval(key + '=data[key]');
  }
});
